const tooltipBtns = document.querySelectorAll('.tooltip-button');
const tooltipCloseBtns = document.querySelectorAll('.btn-close');

const element = document.querySelector('select');
const choices = new Choices(element, {
    searchEnabled: false,
    itemSelectText: '',
    shouldSort: false
});


tooltipBtns.forEach(btn => {
    btn.addEventListener('click', (ev) => {
        const showingTooltips = document.querySelectorAll('.showing');
        showingTooltips.forEach(tooltip => tooltip.classList.remove('showing'));
        const parent = ev.target.closest('.tooltip-holder');
        if (parent) {
            const tooltip = parent.querySelector('.tooltip');
            tooltip.classList.add('showing');
        }
    });
})
tooltipCloseBtns.forEach(btn => {
    btn.addEventListener('click', (ev) => {
        const showingTooltips = document.querySelectorAll('.showing');
        showingTooltips.forEach(tooltip => tooltip.classList.remove('showing'));
    });
})

window.addEventListener('mouseup', e => {
    if (!e.target.classList.contains('tooltip') && !e.target.closest('.tooltip')) {
        const showingTooltips = document.querySelectorAll('.showing');
        showingTooltips.forEach(tooltip => tooltip.classList.remove('showing'));
    }
})


// video
const videoPoset = document.querySelector('.img-poster');
const videoIframe = document.querySelector('.block-video-frame');

videoPoset.addEventListener('click', (e) => {
    const parent = e.target.closest('.right-side');
    if (parent) {
        parent.classList.add('playing')
        videoIframe.src = 'https://vk.ru/video_ext.php?oid=-177771875&id=456239188&hd=4&autoplay=0'
    }
});

// inputs with range

const rangedInputs = document.querySelectorAll('.input-with-range');

rangedInputs.forEach(el => {
    const range = el.querySelector('.range input');
    const input = el.querySelector('.input input');

    range.addEventListener('input', () => {
        input.value = range.value;
    });
    input.addEventListener('input', () => {

        range.value = input.value;
    });
    input.addEventListener('change', () => {
       
        
        if (Number(input.value) < Number(input.min)) {
            input.value = input.min;
        } else if (Number(input.value) > Number(input.max)) {
            input.value = input.max;
        }
    });
})

// calculator

const coefT = {
18:	[683.241, 782.992],
19:	[671.677, 771.205],
20:	[660.124, 759.421],
21:	[648.637, 747.658],
22:	[637.205, 735.868],
23:	[625.789, 724.074],
24:	[614.388, 712.286],
25:	[602.993, 700.523],
26:	[591.611, 688.760],
27:	[580.269, 677.030],
28:	[569.003, 665.312],
29:	[557.788, 653.625],
30:	[546.652, 641.992],
31:	[535.646, 630.374],
32:	[524.772, 618.836],
33:	[514.012, 607.335],
34:	[503.252, 595.844],
35:	[492.612, 584.396],
36:	[482.083, 573.026],
37:	[471.682, 561.739],
38:	[461.420, 550.469],
39:	[451.195, 539.218],
40:	[441.054, 528.018],
41:	[431.125, 516.849],
42:	[421.221, 505.739],
43:	[411.329, 494.643],
44:	[401.465, 483.523],
45:	[391.629, 472.430],
46:	[381.842, 461.405],
47:	[372.117, 450.369],
48:	[362.419, 439.380],
49:	[352.664, 428.388],
50:	[342.982, 417.405],
51:	[333.484, 406.540],
52:	[324.088, 395.619],
53:	[314.803, 384.831],
54:	[305.575, 374.021],
55:	[296.389, 363.216],
56:	[287.455, 352.545],
57:	[278.622, 341.936],
58:	[269.994, 331.423],
59:	[261.392, 320.905],
60:	[252.906, 310.441],
61:	[244.878, 300.097],
62:	[237.050, 289.834],
63:	[229.328, 279.619],
64:	[221.787, 269.508],
65:	[214.373, 259.477],
66:	[207.076, 249.558],
67:	[199.869, 239.711],
68:	[192.999, 230.058],
69:	[186.034, 220.444],
70:	[179.226, 211.019],
71:	[172.98, 201.919],
72:	[166.097, 192.599],
73:	[160.214, 183.849],
74:	[153.499, 174.82],
75:	[146.987, 166.047],
76:	[140.806, 157.509],
77:	[134.155, 148.749],
78:	[129.143, 140.966],
79:	[123.477, 132.911],
80:	[118.174, 125.216],
81:	[113.515, 118.05],
82:	[108.427, 110.841],
83:	[103.719, 104.158],
84:	[99.102, 97.749],
85:	[94.824, 91.67],
86:	[90.379, 85.672],
87:	[86.203, 80.138],
88:	[82.426, 75.105],
89:	[78.083, 70.005],
90:	[73.907, 65.225],
91:	[70.526, 60.872],
92:	[67.194, 56.808],
93:	[63.33, 52.737],
94:	[58.391, 48.423],
95:	[52.151, 43.538],
96:	[45.277, 38.485],
97:	[37.107, 32.428],
98:	[27.773, 25.405],
99:	[17.225, 16.34],
100: [6, 6],
}

const formCalc = document.querySelector('#form-calc');
const resultNakoplenia = document.querySelector('.full-summ');
const resultNalogFirst = document.querySelector('.nalog');
const resultVznosi = document.querySelector('#vznosi');
const resultSofinans = document.querySelector('#sofinans');
const resultNaloginline = document.querySelector('.naloginline');
const resultInvestdohod = document.querySelector('#investdohod');
const resultEdinovremenno = document.querySelector('#edinovremeno');
const resultPogizneno = document.querySelector('#pogizneno');
const resultSrochnaya = document.querySelector('#srochnaya');
const resultSrok = document.querySelector('#srok');
const dohodValue = document.querySelector('.input-dohod');
const periodValue = document.querySelector('#periodity');



function numberWithSpaces(x) {
    const str = x.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    if(str.endsWith('.00')) {
        return str.slice(0, -3) + ' ' + '₽';
    }
    return str + ' ' + '₽';
}

let srokUseCastomData = false;
const ageInput = document.querySelector('.input-age');
    const srokNakoplenijInput = document.querySelector('.srok-nakoplenij');
//изменение макс возраста в зависимости от пола
function changeMaxAge(event) {
    
    if(event.target.value === 'm'){
        ageInput.querySelector('.input input').setAttribute('max', 70);
        ageInput.querySelector('.range input').setAttribute('max', 70);
        ageInput.querySelector('.input input').value = ageInput.querySelector('.range input').value;
        ageInput.querySelector('.range .labels-range p:last-child').innerHTML = ageInput.querySelector('.input input').max;
        
    }else{
        ageInput.querySelector('.input input').setAttribute('max', 70);
        ageInput.querySelector('.range input').setAttribute('max', 70);
        ageInput.querySelector('.input input').value = ageInput.querySelector('.range input').value;
        ageInput.querySelector('.range .labels-range p:last-child').innerHTML = ageInput.querySelector('.input input').max;
    }
}

// function changeSrokNakop(){
//     if(document.querySelector("input[name='radio']:checked").value === 'm'){
//         srokNakoplenijInput.querySelector('.range input').value = (ageInput.querySelector('.range input').value<=45) ? 15 : (60 - ageInput.querySelector('.range input').value);
//         srokNakoplenijInput.querySelector('.input input').value = (ageInput.querySelector('.range input').value<=45) ? 15 : (60 - ageInput.querySelector('.range input').value);
//     }else{
//         srokNakoplenijInput.querySelector('.range input').value = (ageInput.querySelector('.range input').value<=40) ? 15 : (55 - ageInput.querySelector('.range input').value);
//         srokNakoplenijInput.querySelector('.input input').value = (ageInput.querySelector('.range input').value<=40) ? 15 : (55 - ageInput.querySelector('.range input').value);
//     }
// }

//изменение ставки от дохода больше 5млн/год


let stavkaNdfl = 0.13;
    // if(dohodValue.querySelector('.range input').value<= 416666 && !ndfl13.classList.contains('active')){
    //     ndfl13.classList.add('active');
    //     ndfl15.classList.remove('active');
    //     stavkaNdfl24 = 0.13;
    // }else if(dohodValue.querySelector('.range input').value> 416666 && !ndfl15.classList.contains('active')){
    //     ndfl15.classList.add('active');
    //     ndfl13.classList.remove('active');
    //     stavkaNdfl24 = 0.15;
    // }   



function getNdfl24() {

    let stavkaNdfl24 = 0.13;
    if(dohodValue.querySelector('.range input').value<= 416666){
        stavkaNdfl24 = 0.13;
    }else if(dohodValue.querySelector('.range input').value > 416666){
        stavkaNdfl24 = 0.15;
    }   
    return stavkaNdfl24;
}

function changeNdfl(){
    const ndfl13 = document.querySelector('.ndfl-13');
    const ndfl15 = document.querySelector('.ndfl-15');
    const ndfl18 = document.querySelector('.ndfl-18');
    const ndfl20 = document.querySelector('.ndfl-20');
    const ndfl22 = document.querySelector('.ndfl-22');

    const ndflPercentFields = document.querySelectorAll('.ndfl-persent');


    if ((dohodValue.querySelector('.range input').value * 12) < 2400000) {
        stavkaNdfl = 0.13;
        ndflPercentFields.forEach(ndfl => ndfl.classList.remove('active'));
        ndfl13.classList.add('active');

    } else if ((dohodValue.querySelector('.range input').value * 12)<= 5000000){
        stavkaNdfl = 0.15;
        ndflPercentFields.forEach(ndfl => ndfl.classList.remove('active'));
        ndfl15.classList.add('active');

    } else if ((dohodValue.querySelector('.range input').value * 12)<= 20000000){
        stavkaNdfl = 0.18;
        ndflPercentFields.forEach(ndfl => ndfl.classList.remove('active'));
        ndfl18.classList.add('active');

    } else if ((dohodValue.querySelector('.range input').value * 12)<= 50000000){
        stavkaNdfl = 0.20;
        ndflPercentFields.forEach(ndfl => ndfl.classList.remove('active'));
        ndfl20.classList.add('active');

    } else if ((dohodValue.querySelector('.range input').value * 12) > 50000000){
        stavkaNdfl = 0.22;
        ndflPercentFields.forEach(ndfl => ndfl.classList.remove('active'));
        ndfl22.classList.add('active');
    }
}


function changeSrokUseCastomData(){
    if(!srokUseCastomData){
        srokUseCastomData = true;
    }
}

function selectPeriodityCange(e) {
    const periodity = e.target.value;
    const labelVznosi = document.querySelector('#regularvznosLabel');

    switch (periodity) {
        case 'monthly':
            labelVznosi.innerText = 'Ежемесячный взнос, ₽';
            break;
        case 'quartal':
            labelVznosi.innerText = 'Ежеквартальный взнос, ₽';
            break;
        case 'halfyear':
            labelVznosi.innerText = 'Полугодовой взнос, ₽';
            break;
        case 'yearly':
            labelVznosi.innerText = 'Ежегодный взнос, ₽';
            break;
    }
}


srokNakoplenijInput.querySelector('.range input').addEventListener('input', changeSrokUseCastomData);
srokNakoplenijInput.querySelector('.input input').addEventListener('input', changeSrokUseCastomData);
dohodValue.querySelector('.range input').addEventListener('input', changeNdfl);
dohodValue.querySelector('.input input').addEventListener('input', changeNdfl);
document.addEventListener('DOMContentLoaded', changeNdfl);
periodValue.addEventListener('change', selectPeriodityCange);



document.querySelectorAll("input[name='radio']").forEach((input) => {
    input.addEventListener('change', changeMaxAge);
    // input.addEventListener('change', changeSrokNakop);
    document.addEventListener('DOMContentLoaded', changeMaxAge)
});

formCalc.addEventListener('submit', (e) => {
    e.preventDefault();
    
    const gender = e.target.radio.value;
    const age = Number(e.target.age.value);
    const dohod = Number(e.target.dohod.value);
    const firstvznos = Number(e.target.firstvznos.value);
    const regularvznos = Number(e.target.regularvznos.value);
    const periodity = e.target.periodity.value;
    const srok = Number(e.target.srok.value);
    const reinvest = e.target.reinvest.checked;

    let srokNakoplenij = null;

    if(srokUseCastomData){
        srokNakoplenij = srok
    }else{
        if(gender === 'f'){
            srokNakoplenij = (age<=40) ? 10 : (70 - age);
        }else{
            srokNakoplenij = (age<=45) ? 10 : (70 - age);
        }
    }
    e.target.srok.value = srokNakoplenij;

    let coefficient = ( gender === 'm' ? coefT[age+srokNakoplenij][0] : coefT[age+srokNakoplenij][1]);
    let vichetPologen = null;
    if(gender === 'f'){
        vichetPologen = (age< 50);
    }else{
        vichetPologen = (age< 55);
    }


    
    let vznosi = 0;

    // количество платежей в год
    let coefRegular = 0;

    switch (periodity) {
        case 'monthly':
            vznosi = firstvznos + (regularvznos * 11) + regularvznos * 12 * (srokNakoplenij - 1);
            coefRegular = 12;
            break;
        case 'quartal':
            vznosi = firstvznos + (regularvznos * 3) + regularvznos * 4 * (srokNakoplenij - 1);
            coefRegular = 4;
            break;
        case 'halfyear':
            vznosi = firstvznos + regularvznos + regularvznos * 2 * (srokNakoplenij - 1);
            coefRegular = 2;
            break;
        case 'yearly':
            vznosi = firstvznos + regularvznos * (srokNakoplenij - 1);
            coefRegular = 1;
            break;
    }    

    // старая формула только для ежемесячного взноса
    // const vznosi = firstvznos + (regularvznos * 11) + regularvznos * 12 * (srokNakoplenij - 1);
    
    let sofinansPercent;
    
    if(dohod <= 80000) {
        sofinansPercent = 1;
    } else if(dohod > 80000 && dohod <= 150000) {
        sofinansPercent = .5;
    } else {
        sofinansPercent = .25;
    }

    
    
    const sofinansKoef = (srokNakoplenij>=10) ? 9 : srokNakoplenij - 1;
    
    const sofinansYearly = Math.min(regularvznos * 12 * sofinansPercent, 36000) * sofinansKoef;
    const sofinans = Math.min((firstvznos + regularvznos * 11) * sofinansPercent, 36000) + sofinansYearly;
    // console.log('sofin old', sofinans);
    
    
    let koefDohodSofinans = 0; 
    


    if (dohod > 150000) {
        koefDohodSofinans = .25;
    } else if (dohod > 80000) {
        koefDohodSofinans = .5;
    } else {
        koefDohodSofinans = 1;
    }    
        

    const sofinansNew = Math.min(
            (firstvznos + regularvznos * (coefRegular - 1)) * koefDohodSofinans,
            36000) + (Math.min(
                regularvznos * coefRegular * koefDohodSofinans,
                36000)) * sofinansKoef;

    // console.log('new sofin', sofinansNew);
    


    let nalogVichet = 0;

    // максимальный вычет по текущей ставке ндфл
    if(vichetPologen){
        
        let vychetMax = 0;
        switch (stavkaNdfl) {
            case 0.13:
                vychetMax = 52000;
                break;
            case 0.15:
                vychetMax = 60000;
                break;
            case 0.18:
                vychetMax = 72000;
                break;
            case 0.20:
                vychetMax = 80000;
                break;
            case 0.22:
                vychetMax = 88000;
                break;
        }
        
        let vychet24 = 0;
        
        if (new Date().getFullYear() == 2024) {
            let stavkaNdfl24 = getNdfl24();
            let vychetMax24 = 0;

            // максимальный вычет в 24 году
            if(stavkaNdfl24 === 0.13){
                vychetMax24 = 52000;
            }else {
                vychetMax24 = 60000;
            }
            
            // вычет за 24 год по старой ставке ндфл
            vychet24 = Math.min((firstvznos * stavkaNdfl24) + ((regularvznos * (coefRegular - 1)) * stavkaNdfl24), vychetMax24);

            // вычет с 25 года по новой ставке
            nalogVichet = vychet24 + (Math.min((regularvznos * coefRegular * stavkaNdfl), vychetMax) * (srokNakoplenij - 1));
        } else {       
            
            // вычет за по новой ставке
            nalogVichet = Math.min((firstvznos * stavkaNdfl) + ((regularvznos * (coefRegular - 1)) * stavkaNdfl), vychetMax) 
            + (Math.min((regularvznos * coefRegular * stavkaNdfl), vychetMax) * (srokNakoplenij - 1));
        }

    }else{
        nalogVichet = 0;
    }


    let objTemp = {};
    let investDohod = 0;
    let tableNalogVichet = 0;


    function getVznosi(currentYear, periodity) {
        
        if (currentYear <= 0) return 0;
        let tempVzn = 0;
        switch (periodity) {
            case 'monthly':
                currentYear == 1 
                    ? tempVzn = firstvznos + (regularvznos * 11)
                    : tempVzn = regularvznos * 12;
                break;
            case 'quartal':
                currentYear == 1 
                    ? tempVzn = firstvznos + (regularvznos * 3)
                    : tempVzn = regularvznos * 4;
                break;
            case 'halfyear':
                currentYear == 1 
                    ? tempVzn = firstvznos + regularvznos
                    : tempVzn = regularvznos * 2;
                break;
            case 'yearly':
                currentYear == 1 
                    ? tempVzn = firstvznos
                    : tempVzn = regularvznos;
                break;
        }  
        return tempVzn;
    }

    let vychetMax = 0;
    switch (stavkaNdfl) {
            case 0.13:
                vychetMax = 52000;
                break;
            case 0.15:
                vychetMax = 60000;
                break;
            case 0.18:
                vychetMax = 72000;
                break;
            case 0.20:
                vychetMax = 80000;
                break;
            case 0.22:
                vychetMax = 88000;
                break;
    }
    
    let lastVychet = 0;
    for (let i = 1; i<=16; i++){

        objTemp[i] = {};
        objTemp[i].yearFormule = (srokNakoplenij >= i) ? i : 0;
        objTemp[i].ageFormule = (objTemp[i].yearFormule > 0) ?  (i <= 5)  ? (age + objTemp[i].yearFormule - 1) : (age + objTemp[i].yearFormule) : 0;

        

        objTemp[i].vsnosZaGod = getVznosi(objTemp[i].yearFormule, periodity);

        // (objTemp[i].yearFormule > 0) 
        //     ?  (i == 1) 
        //         ? (firstvznos + regularvznos * 11) 
        //         : (regularvznos * 12) 
        //     : 0;


            // const sofinansNew = Math.min(
            // firstvznos + regularvznos * (coefRegular - 1) * koefDohodSofinans,
            // 36000) + (Math.min(
            //     regularvznos * coefRegular * koefDohodSofinans,
            //     36000)) * sofinansKoef;



        if(i == 1){
            objTemp[i].sofinansir = 0
        } else if( i== 2){
            // objTemp[i].sofinansir = (objTemp[i-1].yearFormule > 0) ? Math.min((firstvznos + regularvznos * 11) * sofinansPercent, 36000) : 0;
            // objTemp[i].sofinansir = (objTemp[i-1].yearFormule > 0) ? Math.min((getVznosi(1, periodity)) * sofinansPercent, 36000) : 0;
            if (objTemp[i-1].yearFormule > 0) {

                objTemp[i].sofinansir = Math.min(
                (firstvznos + regularvznos * (coefRegular - 1)) * koefDohodSofinans,
                36000);
            } else objTemp[i].sofinansir = 0;


        } else {
            // objTemp[i].sofinansir = (objTemp[i-1].yearFormule<=10) ? (objTemp[i-1].yearFormule > 0) ? Math.min((getVznosi(2, periodity)) * sofinansPercent, 36000) : 0 : 0;
            
            if ((objTemp[i-1].yearFormule<=10) && (objTemp[i-1].yearFormule > 0)) {

                objTemp[i].sofinansir = Math.min(
                    regularvznos * coefRegular * koefDohodSofinans,
                    36000);
            } else objTemp[i].sofinansir = 0;
            
        }

        // objTemp[i].vichet = 0
        if(i == 1){
            objTemp[i].vichet = 0
        } else if( i == 2){

            // вычет положен
            // реинвест включен
            // год не 0
            if (vichetPologen && (objTemp[i-1].yearFormule > 0) && reinvest) {
                // objTemp[i].vichet = vichetPologen 
                //     ? (objTemp[i-1].yearFormule > 0) 
                //         ? reinvest 
                //             ? (stavkaNdfl === 0.13) 
                //                 ? Math.min((firstvznos * stavkaNdfl) + (regularvznos * 11 * stavkaNdfl), 52000) 
                //                 : Math.min(((firstvznos * stavkaNdfl) + (regularvznos * 11 * stavkaNdfl)) , 60000) 
                //             : 0 
                //         : 0 
                //     : 0;
                
                if (new Date().getFullYear() == 2024) {
                    let stavkaNdfl24 = getNdfl24();
                    let vychetMax24 = 0;

                    // максимальный вычет в 24 году
                    if(stavkaNdfl24 === 0.13){
                        vychetMax24 = 52000;
                    }else {
                        vychetMax24 = 60000;
                    }
                    
                    // вычет за 24 год по старой ставке ндфл
                    vychet24 = Math.min((firstvznos * stavkaNdfl24) + ((regularvznos * (coefRegular - 1)) * stavkaNdfl24), vychetMax24);
                    objTemp[i].vichet = vychet24;
                    

                } else {
                    objTemp[i].vichet = Math.min((firstvznos * stavkaNdfl) + ((regularvznos * (coefRegular - 1)) * stavkaNdfl), vychetMax);
                }

                
            } 
            else objTemp[i].vichet = 0;
            
            
        } else if (i > 2) {
            
            if (vichetPologen && (objTemp[i-1].yearFormule > 1) && reinvest) {
                objTemp[i].vichet = Math.min((regularvznos * coefRegular) * stavkaNdfl, vychetMax);
            } else objTemp[i].vichet = 0;
            
        }

        // вычет за последний гол
        if (i <= srokNakoplenij) lastVychet = objTemp[i].vichet;


            // objTemp[i].vichet = vichetPologen 
            //     ? (objTemp[i-1].yearFormule > 0) 
            //         ? reinvest 
            //             ? (stavkaNdfl === 0.13) 
            //                 ? Math.min((regularvznos * 12) * stavkaNdfl, 52000) 
            //                 : Math.min((regularvznos * 12) * stavkaNdfl, 60000) 
            //             : 0 
            //         : 0 
            //     : 0;
        
        
        tableNalogVichet += objTemp[i].vichet;
        console.log(tableNalogVichet);
        

        
        if(i === 1){
            objTemp[i].nakopleniya = 0
        } else{
            objTemp[i].nakopleniya = (i > 1) 
                ? (objTemp[i-1].yearFormule > 0) 
                    ? (objTemp[i - 1].summ) 
                    : 0 
                : 0;
        }

        
        objTemp[i].investdohod = (objTemp[i].yearFormule > 0) 
        ? +(objTemp[i].nakopleniya * 0.1).toFixed(2) 
        : 0;
        objTemp[i].summ = +(objTemp[i].vsnosZaGod +  objTemp[i].sofinansir + objTemp[i].vichet + objTemp[i].nakopleniya + objTemp[i].investdohod).toFixed(2);
        
        
        investDohod += objTemp[i].investdohod;
    }

    // console.log(tableNalogVichet);
    // console.log(stavkaNdfl);
    console.table(objTemp);
  
    
    // const nakopleniya = vznosi + sofinans + lastVychet + investDohod;
   const nakopleniya = vznosi + sofinansNew + tableNalogVichet + investDohod - lastVychet;
    console.log(vznosi, sofinansNew, tableNalogVichet, investDohod, lastVychet);
    

   const edinovremennaya = nakopleniya;
   const srochnaya = nakopleniya / (5 * 12);
   const pogiznenaya = nakopleniya / coefficient;
    
    
    if(reinvest) {
        resultNalogFirst.classList.add('hide');
        resultNaloginline.classList.remove('hide');
        resultNakoplenia.querySelector('.name').innerHTML = 'Итоговая сумма накоплений'
    } else {
        resultNalogFirst.classList.remove('hide');
        resultNaloginline.classList.add('hide');
        resultNakoplenia.querySelector('.name').innerHTML = 'Накопления'
    }
    
    resultNakoplenia.querySelector('.value').innerHTML = numberWithSpaces(nakopleniya);
    resultNalogFirst.querySelector('.value').innerHTML = numberWithSpaces(nalogVichet);
    resultVznosi.innerHTML = numberWithSpaces(vznosi);
    resultSofinans.innerHTML = numberWithSpaces(sofinansNew);
    resultNaloginline.querySelector('.value').innerHTML = numberWithSpaces(nalogVichet);
    resultInvestdohod.innerHTML = numberWithSpaces(investDohod);
    resultEdinovremenno.innerHTML = numberWithSpaces(edinovremennaya);
    resultSrochnaya.innerHTML = numberWithSpaces(srochnaya);
    resultPogizneno.innerHTML = numberWithSpaces(pogiznenaya);
    if(window.innerWidth < 1200) {
        document.querySelector('#results').scrollIntoView({block: 'start', behavior: 'smooth'});
    }
    
})

// toggle btn
const faqBtn = document.querySelector('.btn-toggle-questions');

faqBtn.addEventListener('click', (e) => {
    const parent = e.target.closest('section');
    if(parent.dataset.opened === 'false') {
        parent.dataset.opened = 'true';
        e.target.querySelector('span').innerHTML = 'Свернуть';
    } else {
        parent.dataset.opened = 'false';
        e.target.querySelector('span').innerHTML = 'Все вопросы';
    }
})


const btnOpenForm = document.querySelectorAll('.js-open-regform');
var formmy = document.querySelector("#popup_form");




btnOpenForm.forEach(btn=>{
    btn.addEventListener('click', (e)=>{
        e.preventDefault();
        MicroModal.show("modal-form", {
            onShow: ()=> document.body.classList.add('popup-opened'),
            onClose: ()=> {document.body.classList.remove('popup-opened');
                formmy.reset();
                document.querySelectorAll('.has-danger').forEach(el=>el.classList.remove('has-danger'));
                document.querySelector('.popupTooltip-wrap').style.display = 'none';
                document.querySelector('.popupTooltip-wrap').style.opacity = 0;
            },
            openClass: 'is-open',
            disableScroll: true,
            disableFocus: false,
            awaitOpenAnimation: false,
            awaitCloseAnimation: false,
            debugMode: true 
        });
    })
})

document.querySelectorAll('.js-close-modal-form').forEach(el=>{
    el.addEventListener('click',(e)=>{
        e.stopPropagation();
        if (document.getSelection().type === "Range") {
            return;
        }
        if(el == e.target){
            MicroModal.close("modal-form");
        }
    })
})


// валидация телефона
document.getElementById('input_phone').addEventListener('input', function (e) {

    let input = e.target.value;
    if (input.startsWith('8')) {
        input = '+7' + input.slice(1);
    }
    e.target.value = input;
}); 

Maska.create(".masked", {
    mask: "+7##########",
});

var pristine = new Pristine(formmy);


const modalOptions = {
    onShow: ()=> document.body.classList.add('popup-opened'),
    onClose: ()=> document.body.classList.remove('popup-opened'),
    openClass: 'is-open',
    disableScroll: true,
    disableFocus: false,
    awaitOpenAnimation: false,
    awaitCloseAnimation: false,
    debugMode: true 
}


// form
// const formSubmit = document.getElementById('#popup_form');
// formSubmit.addEventListener('submit', async () => {
// const formData = new FormData(formSubmit);

//     const response = await fetch('process_form.php', {
//     method: 'POST',
//     body: formData,
//     });

//     const result = await response.json();
//     console.log(JSON.stringify(result));

// });


async function submitForm(e) {
    console.log('submit');
    
   let formData = new FormData(e);

    const response = await fetch('form/form.php', {
        method: 'POST',
        body: formData,
    });

    const result = await response.json();
    console.log(JSON.stringify(result));


//    for (const value of formData.values()) {
//     console.log(value);
//   }
//    await fetch('', {
//     method: 'POST',
//     body: JSON.stringify(Object.fromEntries(formData)),
//     headers: {
//         "Content-Type": "application/json"
//     }
//    }).then(d=>d.json()).then(res=>{
//         MicroModal.close("modal-form", modalOptions);
//     if(res.success){
//         MicroModal.show("modal-success", modalOptions);  
//     }else{
//         MicroModal.show("modal-error", modalOptions);  
//     }
//    }).catch(err=>{
//     MicroModal.show("modal-error", modalOptions);  
//    })
}


function getElementPositionRelativeToParent(element) {
    const parent = element.offsetParent;

    const elementRect = element.getBoundingClientRect();

    const parentRect = parent.getBoundingClientRect();

    const relativeX = elementRect.left - parentRect.left;
    const relativeY = elementRect.top - parentRect.top;

    return { x: relativeX, y: relativeY };
}

formmy.addEventListener('input', function (e) {
    document.querySelector('.popupTooltip-wrap').style.display = 'none';
    document.querySelector('.popupTooltip-wrap').style.opacity = 0;
})

formmy.addEventListener('submit', function (e) {
  e.preventDefault();
   let valid = pristine.validate();
   if(document.querySelector('.has-danger')){
    let position = getElementPositionRelativeToParent(document.querySelectorAll('.has-danger')[0]);
    document.querySelector('.popupTooltip-wrap').style.display = 'block';
    document.querySelector('.popupTooltip-wrap').style.top = position.y + document.querySelectorAll('.has-danger')[0].offsetHeight/1.1 + 'px';
    document.querySelector('.popupTooltip-wrap').style.opacity = 1;
   }else{
    document.querySelector('.popupTooltip-wrap').style.opacity = 0;
   }
  
  
   if(valid){
    submitForm(formmy);
    formmy.reset();
    MicroModal.close("modal-form", modalOptions);
    MicroModal.show("modal-success", modalOptions);
   }
});

//паддинг для попапа чтоб не скакал контент при появлении попапа
function updateScrollBarWidth() {
    const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.documentElement.style.setProperty('--scrollbar-width', `${scrollBarWidth}px`);
}

window.addEventListener("load", () => {
    updateScrollBarWidth()
  });

